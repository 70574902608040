import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const Head = ({ title, description, url, imagePath }) => {
  return (
    <>
      <Helmet title={title ? title : "フォルカフェ"}>
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap"
          rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <link rel="stylesheet" href="bower_components/aos/dist/aos.css" />
        <script src="bower_components/aos/dist/aos.js"></script>

        {/* <title>{props?.title}</title> */}

        {/* ogp */}
        <meta name="description" content={description || ""} />
        <meta property="og:title" content={title || "フォルカフェ"} />
        <meta property="og:description" content={description || ""} />
        <meta
          property="og:url"
          content={url || "https://forustyle-985bb.web.app/"}
        />
        <meta property="og:image" content={imagePath || ""} />
        <meta name="twitter:card" content="" />
        <meta property="og:type" content="website" />
        <meta name="google" content="notranslate" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Marcellus&display=swap"
          rel="stylesheet"
        />
      </Helmet>
    </>
  );
};

const Form = () => {};

export default Head;
